import React from "react"

import Layout from "../components/Layout"
import CV from "../components/CV"

export default () => {
  return (
    <Layout title="cv">
      <CV />
    </Layout>
  )
}
