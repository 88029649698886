import React, { Fragment } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import measurements from "../styles/measurements"

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 4rem;
  background-color: #e6b8af;
  max-width: 50%;
  min-width: 800px;

  @media only screen and (max-width: 1020px) {
    max-width: 100%;
    min-width: 0;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem;
  }

  p,
  h6,
  h1 {
    font-size: 1.5rem;
  }

  p {
    margin-top: 0.75rem;
  }

  h1 {
    margin-top: 2rem;

    &:first-of-type {
      margin: 0;
    }
  }

  h6 {
    margin-top: 1.25rem;
  }
`

const CV = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulCv(title: { eq: "CV" }) {
        body {
          json
        }
      }
    }
  `)

  return (
    <Fragment>
      <Center>
        <Wrapper>
          {documentToReactComponents(data.contentfulCv.body.json)}
        </Wrapper>
      </Center>
    </Fragment>
  )
}

export default CV
